import React, { useState, useEffect } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import { experiences } from "../../assets/utils/data";
import { Chrono } from "react-chrono";
const Resume = () => {
  const width = window.innerWidth;
  const mode = width >= 768 ? "HORIZONTAL" : "VERTICAL";
  const [innerMode, setMode] = useState(mode);

  // useEffect(() => {
  //   // Function to check the device screen width
  //   const updateMode = () => {
  //     if (width >= 768) {
  //       setMode("HORIZONTAL");
  //     } else {
  //       setMode("VERTICAL");
  //     }
  //   };

  //   // Initial check
  //   updateMode();
  // }, []); // Empty dependency array to run only once on mount
  experiences.sort((a, b) => a.order - b.order);
  let items = experiences.map((experience) => {
    return {
      title: experience.title,
      cardTitle: experience.period,
      cardSubtitle: experience.employer,
      cardDetailedText: experience.description,
    };
  });
  return (
    <section id="resume" className="resume">
      <div className="container">
        <div className="section-title">
          <h2>Resume</h2>
          <Slide right>
            <p>
              Developpeur full stack, je participe aux differents aspects du
              developpement de votre projet, depuis son analyse, conception
              jusqu'a son deploiement finale.
            </p>
          </Slide>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Formation et cursus</h3>
            <Fade bottom>
              <div className="resume-item">
                <h4>
                  Licence Professionnelle (Bac +3): systèmes d’information &
                  ingénierie décisionnelle
                </h4>
                <h5>2013 - 2014</h5>
                <p>
                  <em>
                    <a href="http://www.fsts.ac.ma/">
                      Faculté des sciences et techniques
                    </a>
                    Settat, Maroc
                  </em>
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="resume-item">
                <h4>Technicien spéciqlisé en développement informatique</h4>
                <h5>2010 - 2012</h5>
                <p>
                  <em>
                    <a href="https://www.ofppt.ma/fr/etablissements/institut-specialise-de-gestion-et-dinformatique-casablanca">
                      Isgi Casablanca
                    </a>
                  </em>
                </p>
              </div>
            </Fade>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">Experience Professionnelle</h3>
            {experiences.map((experience, index) => (
              <Fade bottom key={index}>
                <div className="resume-item">
                  <h4>{experience.title}</h4>
                  <h5>{experience.period}</h5>
                  <p>
                    <em>{experience.employer}</em>
                  </p>
                  <p>{experience.description}</p>
                </div>
              </Fade>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="resume-title">Experience Professionnelle</h3>
            <Chrono items={items} mode={innerMode} disableToolbar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
